<template>
  <div :id="id">
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">employee no.</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employee.employeeNo }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">name</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employee.fullName }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">employee type</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employee.employeeTypeName }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">work schedule</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employee.workScheduleName }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">work location</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employee.locationName }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">supervisor</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" v-bind:style="[employee.alternateSupervisorName ? {'font-size':'80%'} : {'font-size':'90%'}]">
          {{ employee.supervisorName }}
            <template v-if="employee.alternateSupervisorName">
              &nbsp;/&nbsp;{{ employee.alternateSupervisorName }}
            </template>
          </span>
          <!--<span v-if="employee.alternateSupervisorName" class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:70%"> / {{ employee.alternateSupervisorName }}</span>-->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>

<script>

  export default {
    name: 'employee-info-display',
    props: {
      id: {
        id: String,
        required: false,
        default: 'employeeInfoDisplay'
      },
      employee: {
        type: Object,
        required: true
      }
    }
  }
</script>
