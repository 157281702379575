<template>
  <div>
    <start-date-warning-modal :show="showLeaveStartDateWarning" @noClicked="rejectStartDate" @yesClicked="showLeaveStartDateWarning = false;setEndDate();"></start-date-warning-modal>
    <fieldset v-bind:disabled="isFormDisabled">
      <div v-if="request.id" class="clearfix">
        <div class="float-right">
          <span class="col-md-3">status:</span>
          <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.RequestStatus.Cancelled.value || request.status == Enums.RequestStatus.Rejected.value,  'text-success': request.status == Enums.RequestStatus.Approved.value, 'text-warning': request.status == Enums.RequestStatus.Returned.value }">{{ request.status | toRequestStatusDescription }}</span>
        </div>
      </div>
      <div>
        <div class="form-group row mt-4">
          <label class="col-md-3">child</label>
          <select class="form-control col-md-4" v-model="request.child" required>
            <option value="" disabled>-- Select a child --</option>
            <option v-for="child in children" :value="child">{{ child.name }}</option>
            <!--<option :value="otherChild">{{ otherChild.name }}</option>-->
          </select>
          <div class="col-md-4 invalid-feedback">
            Please select a child from the list.
          </div>
        </div>
        <!--<div v-if="request.child == otherChild">
      <div class="form-group row mt-4">
        <label class="col-md-3">name of child</label>
        <input type="text" class="form-control col-md-4" v-model="request.childName" required />
        <div class="col-md-4 invalid-feedback">
          Please input a valid name for the child.
        </div>
      </div>
      <div class="form-group row mt-4">
        <label class="col-md-3">date of birth</label>
        <input type="date" class="form-control col-md-2" v-model="request.childDateOfBirth" required />
        <span> {{ request.dateOfBirth | toLongDateString }}</span>
        <div class="col-md-4 invalid-feedback">
          please select a valid date.
        </div>
      </div>
      <div class="form-group row mt-4">
        <label class="col-md-3">birth certificateh</label>
        <vue-dropzone id="dropzone" class="col-md-4" ref="birthCertificateDropzone" :options="dropzoneOptions" required></vue-dropzone>
      </div>
    </div>-->
        <div class="form-group row mt-4">
          <label class="col-md-3">number of days</label>
          <input type="number" class="form-control col-md-1" v-model="request.duration" readonly />
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">start date</label>
          <input type="date" id="startDate" class="form-control col-md-2" v-model="request.startDate" required :min="minimumStartDate" :max="maximumStartDate" />
          <span> {{ request.startDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback" v-if="request.child">
            please select a valid date between {{ minimumStartDate }} and {{ maximumStartDate }} (within {{ appSettings.paternityLeave.monthsRequestCanBeMadeWithib }} months of birth).
          </div>
          <div class="col-md-4 invalid-feedback" v-else>
            please select a child and select a valid date within {{ appSettings.paternityLeave.monthsRequestCanBeMadeWithib }} months of birth.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">end date</label>
          <input type="date" class="form-control col-md-2" v-model="request.endDate" readonly />
          <span> {{ request.endDate | toLongDateString }}</span>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">resumption date</label>
          <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly />
          <span> {{ request.resumptionDate | toLongDateString }}</span>
        </div>
        <!--<div class="form-group row mt-4">
      <label class="col-md-3">contact phone #</label>
      <input type="text" class="form-control col-md-2" v-model="request.contactPhoneNumber" required />
      <div class="col-md-4 invalid-feedback">
        please enter a valid phone number.
      </div>
    </div>
    <div class="form-group row mt-4">
      <label class="col-md-3">contact address</label>
      <textarea class="form-control col-md-5" rows="3" v-model="request.contactAddress" required></textarea>
      <div class="col-md-3 invalid-feedback">
        please enter a valid address.
      </div>
    </div>-->
        <div class="form-group row mt-4">
          <label class="col-md-3">remarks</label>
          <textarea class="form-control col-md-5" rows="4" v-model="request.remarks"></textarea>
        </div>
        <!--<div class="form-group row mt-4">
    <span class="col-md-3">approver</span>
    <span class="col-md-7">-->
        <!--{{ employeeInfo.supervisorName }}
    <template v-if="employeeInfo.alternateSupervisorName">
      &nbsp;/&nbsp;{{employeeInfo.alternateSupervisorName }}
    </template>-->
        <!--<employee-autocomplete label="approver" :items="searchedApprovers" id="supv_search" :sData="request.approverName" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="isSavingRequest || isSearchingEmployees" />
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z">
            <title>DOA and Back-To-Back is automatically applied</title>
          </path>
        </svg>
      </span>
    </div>-->
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }
</style>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'
  import store from '@/store'

  import { fromServerDate } from '@/utils/date'
  import isSameDay from 'date-fns/isSameDay'
  import addMonths from 'date-fns/addMonths'
  import subDays from 'date-fns/subDays'
  import { isLeaveStartDateLessThanCurrentDate } from '@/utils/date'

  import Enums from '@/utils/enums'
  import { toRequestStatusDescription } from '@/filters/enum'

  import LeaveRequestEntryFormMixin from '@/mixins/LeaveRequestEntryFormMixin'

  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    name: 'paternity-leave-entry',
    components: {
      StartDateWarningModal,
      vueDropzone: vue2Dropzone
    },
    mixins: [LeaveRequestEntryFormMixin],
    props: {
      id: {
        type: String,
        required: false,
        default: 'roleList'
      },
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      appSettings: {
        type: Object,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      showLeaveStartDateWarning: false,
      Enums: Enums
      //otherChild: {
      //  name: '*Other',
      //  dateOfBirth: null
      //},
      //dropzoneOptions: {
      //  paramName: "files",
      //  url: '/',
      //  maxFiles: 1,
      //  autoProcessQueue: false,
      //  addRemoveLinks: true,
      //  uploadMultiple: false,
      //  timeout: 0,
      //  dictDefaultMessage: 'Drop birth certificate or any other evidence of birth / adoption here or click to upload.',
      //  acceptedFiles: 'image/jpeg,image/png,application/pdf',
      //}
    }),
    filters: {
      toRequestStatusDescription
    },
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
      }),
      children() {
        if (this.employeeInfo.dependants && this.employeeInfo.dependants.length) {
          return this.employeeInfo.dependants.filter(dependant => dependant.relationshipCode === 'S' || dependant.relationshipCode === 'D')
        }
        else {
          return []
        }
      },
      minimumStartDate() {
        return this.request.childDateOfBirth ? fromServerDate(Date.parse(this.request.childDateOfBirth)) : null
      },
      maximumStartDate() {
        const self = this
        if (this.request.childDateOfBirth) {
          return this.request.childDateOfBirth ? fromServerDate(subDays(addMonths(Date.parse(self.request.childDateOfBirth), self.appSettings.paternityLeave.monthsRequestCanBeMadeWithib), 1)) : null
        }
      }
    },
    watch: {
      'request.child'(newValue) {
        //if (newValue == this.otherChild) {
        //  this.request.childName = ''
        //  this.request.childDateOfBirth = ''
        //  this.request.childAddressBookNumber = null
        //}
        //else {
        //  this.request.childName = newValue.name
        //  this.request.childDateOfBirth = newValue.dateOfBirth
        //  this.request.childAddressBookNumber = newValue.addressBookNumber
        //}

        this.request.childName = newValue.name
        this.request.childDateOfBirth = newValue.dateOfBirth
        this.request.childAddressBookNumber = newValue.addressBookNumber
      }
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');

      startDateElement.addEventListener('change', (event) => {
        let selectedDate = new Date(event.target.value)
        var day = new Date(selectedDate).getUTCDay()
        let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
        if (!self.employeeInfo.isFieldEmployee && ([6, 0].includes(day) || startsOnHoliday)) {
          event.preventDefault();
          alert('Weekends / public holidays not allowed');
          event.target.value = '';
          self.request.startDate = null
          self.request.holidays = null
          self.request.endDate = null
          self.request.resumptionDate = null
          startDateElement.click()
          return
        }
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setEndDate()
            }
          }
        })
      })
    }

  }
</script>
