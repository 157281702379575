<script>
  import { mapGetters } from 'vuex'
  import EmployeeInfoDisplay from '@/components/medical/EmployeeInfoDisplay'


  export default {
    components: {
      EmployeeInfoDisplay
    },
    computed: {
      ...mapGetters([
        'clinics',
        'physicians'
      ])
    },
    watch: {
      //'currentUser': {
      //  immediate: true,
      //  handler(newValue) {
      //    if (newValue && newValue.isPhysician) {
      //      this.request.physicianId = newValue.employeeId.toLowerCase()
      //      this.request.physicianName = newValue.fullName.toLowerCase()
      //    }
      //  }
      //},
      'physicians': {
        immediate: true,
        handler(newValue) {
          if (newValue && newValue.length) {
            this.request.physicianId = this.currentUser.isPhysician ? this.currentUser.employeeId.toLowerCase() : null
          }
        }
      },
      'request.clinic': {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.request.clinicCodeTypeId = newValue.codeTypeId
            this.request.clinicId = newValue.id
          }
          else {
            this.request.clinicCodeTypeId = ''
            this.request.clinicId = ''
          }
        }
      },
      'request.physicianId': {
        immediate: true,
        handler(newValue) {
          if (this.isEditable) {
            if (newValue) {
              const selectedPhysician = this.physicians.filter((physician) => {
                return physician.id.toLowerCase() == newValue.toLowerCase()
              })
              if (selectedPhysician.length) {
                this.request.physicianName = this.request.intendedApproverName = selectedPhysician[0].name
                this.request.intendedApproverId = newValue
                return
              }
            }
            this.request.intendedApproverId = this.request.intendedApproverName = this.request.physicianName = null
          }
        }
      }
    }
  }

</script>
