<template>
  <div>
    <data-table :id='id' :headers='headers' :items='itemsToDisplay'>
      <template v-slot:item.requestDate="{ item }">
        {{ item.requestDate | toShortDateString }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ item.startDate | toShortDateString }}
      </template>
      <template v-slot:item.resumptionDate="{ item }">
        {{ item.resumptionDate | toShortDateString }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status | toRequestStatusDescription }}
      </template>
    </data-table>
    <app-pager v-if="numberOfItemsPerPage < numberOfItems" :totalNumberOfItems="numberOfItems" :numberOfItemsPerPage=numberOfItemsPerPage :numberOfPagers="numberOfPagers" :currentPage="currentPage" @paginate="goToPage" />
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>

  import { toRequestStatusDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  import DataTable from '@/components/ui/DataTable'
  import AppPager from '@/components/ui/AppPager'

  export default {
    name: 'sick-leave-request-list',
    components: {
      DataTable,
      AppPager
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'sickLeaveRequestList'
      },
      requests: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      headers: [
        {
          name: 'requestDate',
          title: 'request date'
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'resumptionDate',
          title: 'resumption date'
        },
        {
          name: 'duration',
          title: 'days'
        },
        {
          name: 'clinicName',
          title: 'clinic'
        },
        {
          name: 'physicianName',
          title: 'physician'
        },
        {
          name: 'status',
          title: 'status'
        },
      ],
      numberOfPagers: 7,
      currentPage: 1
    }),
    computed: {
      numberOfItemsPerPage() {
        return this.requests.length
      },
      numberOfItems() {
        return this.requests.length
      },
      itemsToDisplay() {
        return this.requests.slice((this.currentPage - 1) * this.numberOfItemsPerPage, this.numberOfItemsPerPage * this.currentPage)
      }
    },
    watch: {
      itemsToDisplay(value) {
        if (!value.length) {
          this.currentPage = 1
        }
      }
    },
    filters: {
      toRequestStatusDescription,
      toShortDateString
    },
    methods: {
      goToPage(page) {
        this.currentPage = page
      }
    }
  }
</script>
