<template>
  <div id="paternityLeaveRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit paternity leave entry' : isEditable ? 'paternity leave entry': 'view paternity leave entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' request'">
          <div>
            <transition name="slide-fade">
              <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
            </transition>
            <form id="actionForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isActioningRequest">
                <div class="form-group row mt-2">
                  <!--<span class="font-weight-bold">Are you sure you want to {{ selectedAction.description }} this requst?</span>-->
                  <span class="font-weight-bold">comment</span>
                  <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                  <div class="invalid-feedback">
                    Please enter comments for this action.
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isEditable && !isEditPage" v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired ::isDisabled="isSavingRequest || isSearchingEmployees" />
          <div>
            <employee-info-display :employee="employeeInfo" v-if="employeeInfo.employeeId"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="(leaveRequestCanBeRaisedForEmployee) || (!isEditable && request.id)">
      <div class="col">
        <form id="leaveEntryForm" onsubmit="event.preventDefault();" novalidate>
          <PaternityLeaveRequestEntryForm ref="leaveEntryForm" :request="request" :employeeInfo="employeeInfo" :appSettings="appSettings" :isFormDisabled="disableFields" />
          <div class="m-2" v-if="request.id && isEditable">
            <h4>review comment</h4>
            <textarea class="form-control col-md-5 offset-3" rows="4" v-model="comment" required v-bind:disabled="disableFields"></textarea>
          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3"></label>
            <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
            <span>
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z">
                  <title>DOA and Back-To-Back is automatically applied</title>
                </path>
              </svg>
            </span>
          </div>
        </form>
        <div class="offset-3 mt-4" v-if="isEditable">
          <button class="btn btn-danger d-inline mr-2" @click="resetData" v-bind:disabled="isSavingRequest">Cancel</button>
          <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
        </div>
        <div class="offset-3 mt-4" v-else>
          <button class="btn btn-secondary d-inline mr-2" :style="{visibility: canEditRequest(request) ? 'visible' : 'hidden'}" @click="editRequest(request)">Edit</button>
          <button class="btn btn-primary d-inline mr-2" v-if="canApproveRequest(request)" @click="approveRequest(request)" title="Approve request">Approve</button>
          <button class="btn btn-danger d-inline mr-2" v-if="canCancelRequest(request)" @click="cancelRequest(request)" title="Cancel request">Cancel</button>
          <button class="btn btn-danger d-inline mr-2" v-else-if="canApproveRequest(request)" @click="rejectRequest(request)" title="Reject request">Reject</button>
          <button class="btn btn-warning d-inline mr-2" v-if="canApproveRequest(request)" @click="returnRequest(request)" title="Return request">Return</button>
        </div>
      </div>
    </div>
    <div class="row" v-if="request.id">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList :requestActions="request.requestActions"></RequestActionList>
      </div>
    </div>
    <div v-if="isCompleted && isNewRequestPage" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { FETCH_PUBLICHOLIDAYS } from '@/store/action-type'

  import { fromServerDate } from '@/utils/date'

  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'
  import { CLOSE_MODAL_EVENT, ALERT_TYPE_ERROR } from '@/utils/constants'

  import { employeeService, paternityLeaveRequestService, leaveRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import EmployeeInfoDisplay from '@/components/maternityLeave/EmployeeInfoDisplay'
  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'
  import PaternityLeaveRequestEntryForm from '@/components/paternityLeave/EntryForm'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'


  export default {
    name: 'PaternityLeaveRequestView',
    components: {
      EmployeeInfoDisplay,
      StartDateWarningModal,
      PaternityLeaveRequestEntryForm
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin],
    data: () => ({
      request: {
        id: null,
        child: '',
        childAddressBookNumber: null,
        childName: '',
        childDateOfBirth: '',
        startDate: '',
        endDate: '',
        resumptionDate: '',
        duration: 0,
        intendedApproverId: '',
        intendedApproverName: '',
        status: 0,
        remarks: '',
        requestType: Enums.RequestType.PaternityLeave.value,
        requestActions: []
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employeeTypeName: '',
        workScheduleName: '',
        locationName: '',
        gender: '',
        supervisorName: '',
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isTemporalEmployee: false,
        dependants: []
      },
      comment: null,
      approvalOrRejectionErrorMessage: '',
      errorAlertType: ALERT_TYPE_ERROR
    }),
    computed: {
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.gender === Enums.Gender.Male.value)
        }
        return []
      },
      leaveRequestCanBeRaisedForEmployee() {
        if (this.isEditable && this.employeeInfo.employeeId) {
          if (this.employeeInfo.gender !== Enums.Gender.Male.value) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeGender, this.request.requestType)
            return false
          }
          //if (!(this.employeeInfo.isRegularEmployee || this.employeeInfo.isExpatriateEmployee || this.employeeInfo.isTemporalEmployee)) {
          //  this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.request.requestType)
          //  return false
          //}
          if (!this.employeeInfo.isRegularEmployee) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.request.requestType)
            return false
          }
          if (this.request.id && !this.canEditRequest(this.request)) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RequestCannotBeModifiedDueToCurrentState, this.request.requestType)
            return false
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
      disableFields() {
        return !this.isEditable || this.isSavingRequest/* || this.isCancellingRequest*/
      }
    },
    methods: {
      resetOtherData() {
        this.request.duration = this.appSettings.paternityLeave.maximumDurationForSingleRequest
        this.employeeInfo.dependants.splice(0, this.employeeInfo.dependants.length)
        this.request.child = ''
        this.request.childAddressBookNumber = null
        this.request.childName = ''
        this.request.childDateOfBirth = ''
        this.comment = ''
      },
      //submitRequest() {
      //  const self = this
      //  const requestForm = document.getElementById('leaveEntryForm')
      //  if (requestForm.checkValidity() === false) {
      //    requestForm.classList.add('was-validated');
      //  }
      //  else {
      //    if (self.request.childAddressBookNumber === null) {
      //      if (this.$refs.leaveEntryForm.$refs.birthCertificateDropzone.getQueuedFiles().length == 0) {
      //        self.showErrorMessage("Kindly upload a birth certificate or any other evidence of birth/adoption for the child.")
      //        return
      //      }
      //      self.request.files = [{
      //        name: 'birthCertificateFile',
      //        content: this.$refs.leaveEntryForm.$refs.birthCertificateDropzone.getQueuedFiles()[0]
      //      }]
      //    }
      //    self.isSavingRequest = true
      //    store.dispatch(SCHEDULE_LEAVE_REQUEST, self.request)
      //      .then(() => {
      //        self.requestState = Enums.RequestState.Submitted
      //        self.showSuccessMessage('request submitted successfully')
      //        self.resetData()
      //      })
      //      .catch((error) => {
      //        self.showErrorMessage(error)
      //      }).finally(() => {
      //        self.isSavingRequest = false
      //      })
      //  }
      //},
      fetchEmployeeData(employee) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(employeeService.getEmployeeDependants(employee.employeeId))
        tasks.push(paternityLeaveRequestService.getPaternityLeaveRequestsFor(employee.employeeId))
        Promise.all(tasks).then((results) => {
          self.setData(results[0].data, results[1].data, results[2].items)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      fetchExistingData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest(Enums.RequestType.PaternityLeave.value, self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          let tasks = []
          tasks.push(employeeService.getEmployeeDependants(employee.employeeId))
          tasks.push(paternityLeaveRequestService.getPaternityLeaveRequestsFor(employee.employeeId))
          Promise.all(tasks).then((results) => {
            self.setData(employee, results[0].data, results[1].items, request)
          }).catch((error) => {
            self.showErrorMessage(error)
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
        }).catch((error) => {
          eventBus.$emit(Events.LongOperationCompleted)
          self.showErrorMessage(error)
        })
      },
      setData(employee, dependants, previousPaternityRequests, request) {
        if (request) {
          this.setBaseRequestData(request)
          this.request.childAddressBookNumber = request.childAddressBookNumber
          this.request.childDateOfBirth = fromServerDate(request.childDateOfBirth)
          this.request.childName = request.childName
          if (previousPaternityRequests && previousPaternityRequests.length) {
            previousPaternityRequests = previousPaternityRequests.filter(pl => pl.id != request.id)
          }
          const self = this
          self.request.child = dependants.filter(dependant => dependant.addressBookNumber == self.request.childAddressBookNumber)[0]
        }
        this.setEmployeeData(employee)
        if (!request) {
          this.request.intendedApproverId = employee.supervisorId
          this.request.intendedApproverName = employee.supervisorName
        }

        if (dependants && dependants.length) {
          if (previousPaternityRequests && previousPaternityRequests.length) {
            dependants = dependants.filter(dependant => !previousPaternityRequests.some((previousRequest) => previousRequest.childAddressBookNumber === dependant.addressBookNumber))
          }
          this.employeeInfo.dependants = [...dependants]
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, '')
      const tasks = []
      tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, new Date().getFullYear()))
      Promise.all(tasks).then(next(vm => {
        vm.resetData()
        if (vm.$route.params.id) {
          vm.fetchExistingData()
        }
        else {
          vm.pageIsFullyLoaded = true
          vm.isCompleted = false
          vm.fetchEmployeeData(vm.currentUser)
        }
      })).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }).finally(() => {
      })
    },
    mounted() {
      const self = this
      self.request.duration = self.appSettings.paternityLeave.maximumDurationForSingleRequest
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
