<template>
  <div>
    <start-date-warning-modal :show="showLeaveStartDateWarning" @noClicked="rejectStartDate" @yesClicked="showLeaveStartDateWarning = false; setEndDate();"></start-date-warning-modal>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div v-if="request.id" class="clearfix">
          <div class="float-right">
            <span class="col-md-3">status:</span>
            <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.RequestStatus.Cancelled.value || request.status == Enums.RequestStatus.Rejected.value,  'text-success': request.status == Enums.RequestStatus.Approved.value, 'text-warning': request.status == Enums.RequestStatus.Returned.value }">{{ request.status | toRequestStatusDescription }}</span>
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">clinic location</label>
          <select class="form-control col-md-4" v-model="request.clinic" required>
            <option value="" disabled>-- Select a clinic --</option>
            <option v-for="clinic in clinics" :value="clinic">{{ clinic.description }}</option>
          </select>
          <div class="col-md-4 invalid-feedback">
            Please select a clinic.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">maternity type</label>
          <select class="form-control col-md-4" v-model="request.maternityType" required>
            <option value="" disabled>-- Select maternity type --</option>
            <option v-for="maternityType in maternityTypes" :value="maternityType.value">{{ maternityType.description }}</option>
          </select>
          <div class="col-md-4 invalid-feedback">
            Please select a maternity type.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">number of days</label>
          <input type="number" class="form-control col-md-1" min="1" v-model="request.duration" readonly />
        </div>
        <!--<div class="form-group row mt-4">
          <label class="col-md-3">weeks of pregnancy</label>
          <input type="number" class="form-control col-md-1" v-model="request.numberOfPregnancyWeeks" min="1" v-bind:required="request.maternityType === maternityTypes.Delivery.value" v-bind:readonly="request.maternityType === maternityTypes.Adoption.value" />
          <div class="col-md-4 invalid-feedback">
            Please input a valid number for the number of weeks.
          </div>
        </div>-->
        <div class="form-group row mt-4">
          <label class="col-md-3">start date</label>
          <input type="date" id="startDate" class="form-control col-md-2" v-model="request.startDate" required />
          <span> {{ request.startDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            please select a valid date.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">end date</label>
          <input type="date" class="form-control col-md-2" v-model="request.endDate" readonly />
          <span> {{ request.endDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            please select a valid start date and duration.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">resumption date</label>
          <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly />
          <span> {{ request.resumptionDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            please select a valid start date and duration.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">physician</label>
          <select class="form-control col-md-4" v-model="request.physicianId" required>
            <!--<select class="form-control col-md-4" v-model="request.physicianId" v-bind:disabled="currentUser.isPhysician && request.physicianId.toLowerCase() == currentUser.employeeId.toLowerCase() && (currentUser.employeeId.toLowerCase() != employeeInfo.employeeId.toLowerCase())" required>-->
            <option value="" disabled>-- Select physician --</option>
            <option v-for="physician in physicians" v-if="physician.id.toLowerCase() != employeeInfo.employeeId.toLowerCase()" :value="physician.id.toLowerCase()">{{ physician.name }}</option>
          </select>
          <div class="col-md-4 invalid-feedback">
            please select a physician.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">remarks</label>
          <textarea class="form-control col-md-5" rows="4" v-model="request.remarks"></textarea>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }
</style>

<script>

  import { mapState, mapGetters } from 'vuex'

  import Enums from '@/utils/enums'
  import { getLeaveDates } from '@/utils/date'

  import LeaveRequestEntryFormMixin from '@/mixins/LeaveRequestEntryFormMixin'

  export default {
    name: 'maternity-leave-entry',
    mixins: [LeaveRequestEntryFormMixin],
    props: {
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      physicians: {
        type: Array,
        required: true
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      maternityTypes: Enums.MaternityTypes,
      Enums: Enums
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        appSettings: state => state.applicationSettingsModule.appSettings
      }),
      ...mapGetters([
        'clinics'
      ])
    },
    watch: {
      'request.maternityType'(newValue, oldValue) {
        if (newValue === this.maternityTypes.Delivery.value) {
          this.request.duration = this.appSettings.maternityLeave.durationForDelivery
        }
        else {
          this.numberOfPregnancyWeeks = null
          this.request.duration = this.appSettings.maternityLeave.durationForAdoption
        }
      }//,
      //'request.physicianId': {
      //  immediate: true,
      //  handler(newValue, oldValue) {
      //    if (newValue) {
      //      this.request.physicianName = this.physicians.filter((physician) => {
      //        return physician.id == newValue
      //      })[0].name
      //    }
      //    else {
      //      this.request.physicianName = null
      //    }
      //  }
      //},
    },
    methods: {
      setEndDate() {
        if (this.request.startDate && this.request.duration) {
          var dates = getLeaveDates(this.request.startDate, this.request.duration, true, this.employeeInfo.isFieldEmployee, [this.holidayDates])
          this.request.endDate = dates.endDate
          this.request.resumptionDate = dates.resumptionDate
        }
      }
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');
      startDateElement.addEventListener('change', (event) => {
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setEndDate()
            }
          }
        })
      })
    }
  }
</script>
