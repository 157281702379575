var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"id":_vm.id,"headers":_vm.headers,"items":_vm.itemsToDisplay},scopedSlots:_vm._u([{key:"item.requestDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.requestDate))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.startDate))+" ")]}},{key:"item.resumptionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.resumptionDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRequestStatusDescription")(item.status))+" ")]}}])}),(_vm.numberOfItemsPerPage < _vm.numberOfItems)?_c('app-pager',{attrs:{"totalNumberOfItems":_vm.numberOfItems,"numberOfItemsPerPage":_vm.numberOfItemsPerPage,"numberOfPagers":_vm.numberOfPagers,"currentPage":_vm.currentPage},on:{"paginate":_vm.goToPage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }