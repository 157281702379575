<template>
  <div id="maternityLeaveRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit maternity leave entry' : isEditable ? 'maternity leave entry': 'view maternity leave entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' request'">
          <div>
            <transition name="slide-fade">
              <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
            </transition>
            <form id="actionForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isActioningRequest">
                <div class="form-group row mt-2">
                  <!--<span class="font-weight-bold">Are you sure you want to {{ selectedAction.description }} this requst?</span>-->
                  <span class="font-weight-bold">comment</span>
                  <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                  <div class="invalid-feedback">
                    Please enter comments for this action.
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="pageIsFullyLoaded && isNewRequestPage" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired :isDisabled="isSavingRequest || isSearchingEmployees" />
          <div>
            <employee-info-display :employee="employeeInfo" v-if="employeeInfo.employeeId"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="(leaveRequestCanBeRaisedForEmployee) || (!isEditable && request.id)">
      <div class="col">
        <form id="leaveEntryForm" onsubmit="event.preventDefault();" novalidate>
          <MaternityLeaveRequestEntryForm :request="request" :employeeInfo="employeeInfo" :physicians="physicianList" :isFormDisabled="disableFields" />
          <div class="m-2" v-if="request.id && isEditable">
            <h4>review comment</h4>
            <textarea class="form-control col-md-5 offset-3" rows="4" v-model="comment" required v-bind:disabled="disableFields"></textarea>
          </div>
        </form>
        <div class="offset-3 mt-4" v-if="isEditable">
          <button class="btn btn-danger d-inline mr-2" @click="resetData" v-bind:disabled="isSavingRequest">Cancel</button>
          <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
        </div>
        <div class="offset-3 mt-4" v-else-if="isReviewPage">
          <button class="btn btn-secondary d-inline mr-2" :style="{visibility: canEditRequest(request) ? 'visible' : 'hidden'}" @click="editRequest(request)">Edit</button>
          <button class="btn btn-primary d-inline mr-2" v-if="canApproveRequest(request)" @click="approveRequest(request)" title="Approve request">Approve</button>
          <button class="btn btn-danger d-inline mr-2" v-if="canCancelRequest(request)" @click="cancelRequest(request)" title="Cancel request">Cancel</button>
          <button class="btn btn-danger d-inline mr-2" v-else-if="canApproveRequest(request)" @click="rejectRequest(request)" title="Reject request">Reject</button>
          <button class="btn btn-warning d-inline mr-2" v-if="canApproveRequest(request)" @click="returnRequest(request)" title="Return request">Return</button>
        </div>
      </div>
    </div>
    <div class="row" v-if="request.id">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList :requestActions="request.requestActions"></RequestActionList>
      </div>
    </div>
    <div v-if="isCompleted && isNewRequestPage" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'

  import { FETCH_USERS, FETCH_CODETYPES } from '@/store/action-type'
  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, leaveRequestService } from '@/services'
  import { CLOSE_MODAL_EVENT, ALERT_TYPE_ERROR } from '@/utils/constants'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'
  import MaternityLeaveRequestEntryForm from '@/components/maternityLeave/EntryForm'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import MedicalLeaveRequestMixin from '@/mixins/leaveRequest/medical'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'


  export default {
    name: 'MaternityLeaveRequestView',
    components: {
      StartDateWarningModal,
      MaternityLeaveRequestEntryForm
    },
    mixins: [GeneralMixin, LeaveRequestMixin, MedicalLeaveRequestMixin, LeaveRequestActionsMixin],
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    data: () => ({
      request: {
        id: null,
        startDate: '',
        endDate: '',
        resumptionDate: '',
        duration: null,
        maternityType: '',
        clinic: null,
        clinicCodeTypeId: '',
        clinicId: '',
        physicianId: '',
        physicianName: '',
        intendedApproverId: '',
        intenddApproverName: '',
        remarks: '',
        status: 0,
        requestType: Enums.RequestType.MaternityLeave.value,
        requestActions: []
      },
      physicianList: [],
      comment: null,
      showCancelModal: false,
      isCancellingRequest: false,
      approvalOrRejectionErrorMessage: '',
      errorAlertType: ALERT_TYPE_ERROR
    }),
    computed: {
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.employeeId && employee.employeeId.toLowerCase() !== this.currentUser.id.toLowerCase() && employee.gender === Enums.Gender.Female.value && (employee.isRegularEmployee || employee.isExpatriateEmployee || employee.isTemporalEmployee))
        }
        return []
      },
      leaveRequestCanBeRaisedForEmployee() {
        if (this.isEditable && this.employeeInfo.employeeId) {
          if (this.employeeInfo.employeeId.toLowerCase() === this.currentUser.id.toLowerCase()) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestForSelf, this.request.requestType)
            return false
          }
          if (this.employeeInfo.gender !== Enums.Gender.Female.value) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeGender, this.request.requestType)
            return false
          }
          if (!(this.employeeInfo.isRegularEmployee || this.employeeInfo.isExpatriateEmployee || this.employeeInfo.isTemporalEmployee)) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.request.requestType)
            return false
          }
          if (this.request.id && (this.request.status == Enums.RequestStatus.Approved.value) && this.isEditable) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RequestCannotBeModifiedDueToCurrentState, this.request.requestType)
            return false
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
    },
    methods: {
      resetOtherData() {
        this.request.maternityType = ''
        this.request.clinic = null
        this.request.clinicId = ''
        this.request.clinicCodeTypeId = ''
      },
      fetchEmployeeData(employee) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        employeeService.getEmployee(employee.employeeId).then((result) => {
          employee = result.data
          self.setData(employee, null)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      fetchExistingData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest(Enums.RequestType.MaternityLeave.value, self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          self.setData(employee, request)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, request) {
        this.physicianList = [...this.physicians]
        if (request) {
          this.setBaseRequestData(request)
          this.request.maternityType = request.maternityType
          this.request.clinicId = request.clinicId
          this.request.physicianId = request.physicianId
          this.request.physicianName = request.physicianName
          this.request.clinic = this.clinics.filter((clinic) => clinic.id == this.request.clinicId)[0]
          if (!this.isEditable) {
            const selectedPhysician = this.physicians.filter((physician) => {
              return physician.id.toLowerCase() == this.request.physicianId.toLowerCase()
            })
            if (!selectedPhysician.length) {
              this.physicianList.push({
                id: this.request.physicianId,
                name: this.request.physicianName
              })
            }
          }
        }
        this.setEmployeeData(employee)
        this.pageIsFullyLoaded = true
      },
      //tryCancelRequest() {
      //  if (this.$route.params.id) {
      //    const self = this
      //    self.showCancelModal = true
      //  }
      //  else {
      //    this.resetData()
      //  }
      //},
      //onCancelRequestCancellation() {
      //  this.showCancelModal = false
      //},
      //onCancelRequestConfirmed() {
      //  const self = this
      //  self.showCancelModal = false
      //  self.isCancellingRequest = true
      //  leaveRequestService.cancelRequest(self.request.id, self.request.requestType).then(() => {
      //    self.resetData()
      //    self.showSuccessMessage("request cancelled successfully")
      //  }).catch((error) => {
      //    self.showErrorMessage(error)
      //  }).finally(() => {
      //    self.isCancellingRequest = false
      //  })
      //},
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, '')
      const tasks = []
      tasks.push(store.dispatch(FETCH_CODETYPES))
      tasks.push(store.dispatch(FETCH_USERS))
      Promise.all(tasks).then(next(vm => {
        vm.resetData()
        if (vm.$route.params.id) {
          vm.fetchExistingData()
        }
        else {
          vm.pageIsFullyLoaded = true
          vm.isCompleted = false
          eventBus.$emit(Events.LongOperationCompleted)
        }
      })).catch((error) => {
          next(vm => {
            vm.showErrorMessage(error)
            eventBus.$emit(Events.LongOperationCompleted)
          })
      })
    },
    mounted() {
      const self = this
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
